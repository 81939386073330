import {defineStore} from 'pinia'
import ApiCallsService from "@/services/apiCalls.service";

export const useApiCallsStore = defineStore({
	id: 'apiCalls',
	persist: true,
	state: () => ({
		countryList: [],
		stateList: [],
	}),
	getters: {},
	actions: {
		async makeDonation(donation) {
			return ApiCallsService.makeDonation(donation)
				.then(data => {
						return Promise.resolve(data)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async makePurchase(purchase) {
			return ApiCallsService.makePurchase(purchase)
				.then(data => {
						return Promise.resolve(data)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async GetStatesAndCountries(options) {
			return ApiCallsService.getStatesAndCountries(options)
				.then(data => {
						this._setCountryList(data.countries)
						this._setStateList(data.states)

						return Promise.resolve(data)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async InviteNewFriend(invitation) {
			return ApiCallsService.InviteNewFriend(invitation)
				.then(data => {
						return Promise.resolve(data)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async RegisterExistingFriend(existingFriend) {
			return ApiCallsService.registerExistingFriend(existingFriend)
				.then(data => {
						return Promise.resolve(data)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async RegisterNewFriend(newFriend) {
			return ApiCallsService.registerNewFriend(newFriend)
				.then(data => {
						return Promise.resolve(data)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},

		async UpdateFriend(updatedFriend) {
			return ApiCallsService.updateExistingFriend(updatedFriend)
				.then(data => {
						return Promise.resolve(data)
					},
					error => {
						return Promise.reject(error)
					}
				);
		},


		// *** private actions ***
		_filterOutMilitaryMail(stateList) {
			return stateList.filter(state => {
				return state.name !== "Military Mail (Americas)" && state.name !== "Military Mail (Europe)" && state.name !== "Military Mail (Pacific)"
			})
		},
		_setCountryList(countryList) {
			this.countryList = countryList
			return countryList
		},
		_setStateList(stateList) {
			this.stateList = []
			const filteredStates = this._filterOutMilitaryMail(stateList)

			this.stateList = filteredStates
			return filteredStates
		},
	}
})
