<template>
  <div ref="mainHeading" class="inner-wrapper main_content">
    <div class="main_heading">
      <div v-if="!submissionSuccessful">
        <h3>{{ cms.page.fgInviteOthersToBeAFriend }}</h3>
        <p>{{ cms.page.fgDoYouKnowSomeOne }}</p>
        <p>{{ cms.page.fgSimplyComplete }}</p>
      </div>

      <div v-if="!submissionSuccessful">
        <!-- Friend's/Gideon's information -->
        <div class="form-wrapper">
          <div key="1" class="input-field">
            <label class="label">
              {{ cms.page.fgYourFirstName }}<span class="star">*</span>
            </label>
            <Input
                type="text"
                v-model.trim="inviteYourFirstName"
                :error="v$.inviteYourFirstName"
            />
          </div>

          <div key="2" class="input-field">
            <label class="label">
              {{ cms.page.fgYourLastName }}<span class="star">*</span>
            </label>
            <Input
                type="text"
                v-model.trim="inviteYourLastName"
                :error="v$.inviteYourLastName"
            />
          </div>

          <div key="3" class="input-field">
            <label class="label">
              {{ cms.page.fgYourEmail }}<span class="star">*</span>
            </label>
            <Input
                type="text"
                v-model.trim="inviteYourEmail"
                :error="v$.inviteYourEmail"/>
          </div>
        </div>

        <!-- Invitee's Information -->
        <div class="form-wrapper">
          <div key="4" class="input-field">
            <label class="label">
              {{ cms.page.fgRecipientFirstName }}<span class="star">*</span>
            </label
            >
            <Input
                type="text"
                v-model.trim="inviteFirstName"
                :error="v$.inviteFirstName"
            />
          </div>

          <div key="5" class="input-field">
            <label class="label">
              {{ cms.page.fgRecipientLastName }}<span class="star">*</span>
            </label>
            <Input
                type="text"
                v-model.trim="inviteLastName"
                :error="v$.inviteLastName"
            />
          </div>

          <div key="6" class="input-field">
            <label class="label">
              {{ cms.page.fgRecipientEmail }}<span class="star">*</span>
            </label>
            <Input type="text" v-model.trim="inviteEmail" :error="v$.inviteEmail" v-on:keyup.enter="formSubmit"/>
          </div>
        </div>

        <div class="input-row">
          <p v-if="formErrors" class="input-error">
            {{ cms.page.fgErrorMessage }}
          </p>
          <p v-if="registerError" class="input-error">{{ registerError }}</p>
        </div>

        <div class="buttons-row">
          <button
              type="submit"
              class="btn btn-default"
              v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }"
              @click="formSubmit"
          >
            {{ cms.page.fgSendInvite }}
          </button>
        </div>
      </div>
      <div v-else class="buttons-row">
        <h4>
          {{ cms.page.fgThankYouForInviting }} {{ inviteFirstName }}
          {{ inviteLastName }}!
        </h4>
        <p>{{ cms.page.fgWouldYouLikeToInviteSomeOneElse }}</p>
        <button type="submit" class="button btn btn-default" @click="resetPage">
          {{ cms.page.fgYes }}!
        </button>
      </div>
    </div>
    <div class="side-image-wrapper">
      <img
          src="../assets/images/inviteFriends/share2.jpg"
          class="img-fluid invite_friend_img"
      />
    </div>

  </div>
  <div class="social_network">
    <h3 class="get-social">{{ cms.page.fgLetsGetSocial }}</h3>
    <h5>{{ cms.page.fgClickOnASocialMediaIcon }}</h5>
    <div class="row_twitter_img">
      <div
          class="icons-wrapper"
          v-for="(cardIcon, index) in social_icons"
          :class="{ active: currentIcon === cardIcon.icon }"
          @click="handleSelectCard(cardIcon)"
          :key="index"
      >
        <i class="icon fa-brands" :class="`fa-${cardIcon.icon}`"></i>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="container">
      <h5>{{ getCurrentCard.title }}</h5>
    </div>
    <div class="social_wrapper">
      <div class="social_img_title">
        <img
            src="../assets/images/inviteFriends/user.png"
            alt=""
            class="img-fluid social_img"
        />
        <div>
          <div class="social_name">
            <h3>{{ cms.page.fgYourName }}</h3>
            <p>@your_name</p>
          </div>
        </div>
      </div>

      <div class="social_para">
        <textarea
            placeholder="write here"
            v-model="cards[currentCardName].para"
            @input="assertMaxChars(currentCardName)"
        ></textarea>
        <div class="social_comments">
          <h6>
            <span :class="{ 'text-red': (cards[currentCardName].para.length > cards[currentCardName].maxChar) }">
             {{ cards[currentCardName].para.length }}
            </span>
            /{{ cards[currentCardName].maxChar }}
          </h6>
        </div>
        <div class="social_btn">
          <button type="submit" @click="socialShare">
            {{ getCurrentCard.btnSocialIcon }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTemplate from "@/components/FormTemplate";
import Input from "@/components/Input";
import useVuelidate from "@vuelidate/core";
import {email, helpers, maxLength, required} from "@vuelidate/validators";
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore"
import {useApiCallsStore} from "@/stores/apiCallsStore";
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";
// var VueScrollTo = require('vue-scrollto');

export default {
  name: "InviteOthers",
  components: {FormTemplate, Input},
  setup: () => ({v$: useVuelidate({$lazy: false, $autoDirty: true})}),
  data() {
    return {
      cms: {
        loaded: false,
        options: {
          Common: true,
          Components: ["input", "login_modal"],
          LanguageCode: "",
          PageName: "invite_others",
          MinistryUpdates: false,
        },
        page: {},
      },
      formActivelySubmitting: false,
      formErrors: false,
      registerError: false,
      submissionSuccessful: false,
      social_icons: [
        {icon: "twitter", name: "twitter"},
        {icon: "facebook-f", name: "facebook"},
      ],
      currentIcon: "twitter",
      inviteEmail: "",
      inviteFirstName: "",
      inviteLastName: "",
      inviteYourEmail: "",
      inviteYourFirstName: "",
      inviteYourLastName: "",
      currentCardName: "twitter",
      cards: {
        twitter: {
          btnSocialIcon: "tweet",
          maxChar: 280,
          para: "Is God calling you to pray for members of The Gideons International to win others to the Lord Jesus Christ? Friends have opportunities to pray and financially support Gideons around the world. Visit FriendsOfGideons.org and consider signing up today. #friendsofgideons",
          title: "Share this tweet with your followers:",
          url: 'https://twitter.com/intent/tweet?text=',
        },
        facebook: {
          btnSocialIcon: "facebook",
          maxChar: 280,
          para: "Is God calling you to pray for members of The Gideons International to win others to the Lord Jesus Christ? Friends have opportunities to pray and financially support Gideons around the world. Visit FriendsOfGideons.org and consider signing up today. #friendsofgideons",
          title: "Share this post on Facebook:",
          url: 'https://www.facebook.com/sharer/sharer.php?u=https://www.friendsofgideons.org&src=sdkpreparse&quote=',
        },
      },
    };
  },
  computed: {
    ...mapStores(useApiCallsStore, useAuthStore, useCmsStore, useDesignStore),
    getCurrentCard() {
      return this.cards[this.currentCardName];
    },
  },
  async created() {
    this.submissionSuccessful = false;
    this.setContentLoaded(false);
    this.setTitlePageFromCms()

    if (this.authStore.isLoggedIn) this.setReferringMembersInfo()

    await this.fetchCmsData();
  },
  methods: {
    buildInviteOthersJson() {
      return {
        FirstName: this.inviteFirstName,
        LastName: this.inviteLastName,
        Email: this.inviteEmail,
        ReferFirstName: this.inviteYourFirstName,
        ReferLastName: this.inviteYourLastName,
        ReferEmail: this.inviteYourEmail
      };
    },
    setReferringMembersInfo() {
      const firstName = this.authStore.userDetails.firstName ? this.authStore.userDetails.firstName : ""
      const lastName = this.authStore.userDetails.lastName ? this.authStore.userDetails.lastName : ""
      const email = this.authStore.userDetails.email ? this.authStore.userDetails.email : ""

      this.inviteYourFirstName = firstName
      this.inviteYourLastName = lastName
      this.inviteYourEmail = email

      return true
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgInviteOthers ? page.fgInviteOthers : 'Invite Others'
      const pageSubtitle = page && page.fgReferAFriend ? page.fgReferAFriend : 'Refer a Friend'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "bg-friend",
        btn_history: false,
      });
    },
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setContentLoaded(true);
              this.setSocialMediaContentFromCms();
              this.setTitlePageFromCms(results)
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    setSocialMediaContentFromCms() {
      this.cards.twitter.para = this.cms.page.fgCardsTwitterPara;
      this.cards.twitter.title = this.cms.page.fgCardsTwitterTitle;

      this.cards.facebook.para = this.cms.page.fgCardsFacebookPara;
      this.cards.facebook.title = this.cms.page.fgCardsFacebookTitle;
    },
    async formSubmit() {
      if (this.formActivelySubmitting) return false;

      this.setFormErrorMessage(false); // reset form error message
      this.setRegisterErrorMessage(""); // reset register error message
      this.setFormBeingActivelySubmitted(true);

      let loader = null;
      const isFormValid = await this.v$.$validate();
      try {
        loader = this.$loading.show();
        if (isFormValid) {
          const newInvitation = this.buildInviteOthersJson();
          const response = await this.InviteOthers(newInvitation);
          await this.handleServerResponse(response);
        } else {
          this.setFormErrorMessage(true);
          return false;
        }
      } catch (e) {
        console.error("Error in InviteOthers.vue, ", e);
      } finally {
        loader.hide();
        this.setFormBeingActivelySubmitted(false);
      }
    },
    handleSelectCard(cardIcon) {
      this.currentIcon = cardIcon.icon;
      this.currentCardName = cardIcon.name;
    },
    async handleServerResponse(res) {
      if (res === true) {
        // successful invitation
        this.submissionSuccessful = true;
        this.setRegisterErrorMessage("");
        this.scrollToTopOfFormText()
        return true;
      }

      let errMessage = this.cms.page.fgFriendErrorMessage;

      if (res && typeof res === "string") {
        errMessage = res;
      }
      this.setRegisterErrorMessage(errMessage);
    },
    resetPage() {
      this.formActivelySubmitting = false;
      this.formErrors = false;
      this.registerError = false;
      this.submissionSuccessful = false;
      this.inviteFirstName = "";
      this.inviteLastName = "";
      this.inviteEmail = "";
      this.v$.$reset();
    },
    async InviteOthers(newInvitation) {
      return await this.apiCallsStore.InviteNewFriend(newInvitation);
    },
    scrollToTopOfFormText() {
      const options = {
        easing: 'ease-in',
        lazy: false,
        offset: -200,
        force: true,
        cancelable: true,
        x: false,
        y: true
      }

      const element = this.$refs['mainHeading'];
      this.$scrollTo(element, 400, options)
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setFormErrorMessage(status) {
      this.formErrors = status;
    },
    setRegisterErrorMessage(message) {
      this.registerError = message;
    },
    setFormBeingActivelySubmitted(status) {
      this.formActivelySubmitting = status;
    },
    shareToTwitter() {
      let url = this.cards.twitter.url
      let text = this.cards.twitter.para.replace(/\s/g, "%20");
      text = this.cards.twitter.para.replace(/#/g, "%23");

      return window.open(`${url}${text}`, "_blank");
    },
    socialShare(e) {
      const socialType = e.target.innerHTML.toLowerCase();

      if (socialType === "facebook") {
        FB.ui({
          method: 'share',
          href: 'https://www.friendsofgideons.org/',
          quote: this.cards.facebook.para,
        }, function (response) {
        });
      } else if (socialType === "tweet") {
        this.shareToTwitter()
      }
    },
  },

  async validations() {
    return {
      inviteEmail: {
        required: helpers.withMessage('Email is required', required),
        maxLengthValue: helpers.withMessage('Email cannot be longer than 100 characters', maxLength(100)),
        email,
      },
      inviteFirstName: {
        required: helpers.withMessage('First name is required', required),
        maxLengthValue: helpers.withMessage('First name cannot be longer than 50 characters', maxLength(50)),
      },
      inviteLastName: {
        required: helpers.withMessage('Last name is required', required),
        maxLengthValue: helpers.withMessage('Last name cannot be longer than 50 characters', maxLength(50)),
      },
      inviteYourEmail: {
        required: helpers.withMessage('Your Email is required', required),
        maxLengthValue: helpers.withMessage('Your Email cannot be longer than 100 characters', maxLength(100)),
        email,
      },
      inviteYourFirstName: {
        required: helpers.withMessage('Your First name is required', required),
        maxLengthValue: helpers.withMessage('Your First name cannot be longer than 50 characters', maxLength(50)),
      },
      inviteYourLastName: {
        required: helpers.withMessage('Your Last name is required', required),
        maxLengthValue: helpers.withMessage('Your Last name cannot be longer than 50 characters', maxLength(50)),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 46px 115px 120px;

  @media screen and (max-width: $md) {
    padding: 25px 15px 50px;
  }
  @media screen and (max-width: $xxl) {
    padding: 0;
  }
}

.invite-others {
  color: #0c2e3c;
  height: 500px;
}


.main_content {
  display: flex;
  justify-content: space-around;

  .form-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;

    .input-field {
      width: 48%;
      margin: 10px 0;

      &:last-child {
        width: 100%;
      }

      .label {
        text-transform: capitalize;
        font-weight: 500;
      }

      @media screen and (max-width: $sm) {
        width: 100%;
      }

      .star {
        color: $red;
        font-size: 20px;
      }
    }
  }

  .form-wrapper:first-of-type {
    margin-bottom: 80px;
  }

  h4 {
    color: #0c2e3c;
  }

  p {
    margin-top: 28px;
    color: #0c2e3c;
  }

  .form-actively-submitting {
    cursor: not-allowed;
  }

  .input-error {
    color: $red;
    font-size: 18px;
    font-weight: 500;
  }

  @media screen and (max-width: $md) {
    flex-wrap: wrap;
  }
}

.inner-wrapper.main_content {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px;

  > * {
    flex-basis: 100%;
  }

  @media screen and (max-width: $sm) {
    padding: 50px 25px;
  }
  @include MQ(M) {
    .main_heading {
      flex-basis: 55%;
    }
    .side-image-wrapper {
      flex-basis: 35%;
    }
  }
}

.invite_friend_img {
  object-fit: cover;
  height: 70%;
  object-position: 60% center;
  @media screen and (max-width: $md) {
    width: 100%;
    padding: 25px 23px;
  }
  @media screen and (max-width: $sm) {
    padding: 25px 23px;
    width: 100%;
  }
}

.buttons-row {
  margin-top: 2rem;

  button:first-child {
    margin-right: 50px;
    font-weight: 500;
    text-transform: capitalize;
  }

  @media screen and (max-width: $md) {
    margin-top: 55px;

    button:first-child {
      margin-right: 25px;
    }

    button {
      max-width: 140px;
      min-width: 140px;
    }
  }

  @media screen and (max-width: 450px) {
    button {
      max-width: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: $sm) {
    margin-top: 35px;

    button:first-child {
      margin-right: 15px;
    }
  }
}

.social_network {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: $xxl) {
    margin-top: 35px;
  }

  h4 {
    text-transform: uppercase;
  }

  h5 {
    padding: 13px;
    font-weight: 400;
  }

  .row_twitter_img {
    padding: 23px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icons-wrapper {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      &.active {
        background: #1d9bf0;

        &::after {
          content: "";
          position: absolute;
          margin-top: 117px;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 25px solid #e5f4ff;
          @media (min-width: 400px) and (max-width: 1500px) {
            margin-left: 0;
          }
        }

        .icon {
          color: white;
        }
      }

      .icon {
        color: black;
        font-size: 30px;
      }

      img {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
  margin: 20px auto 20px auto;
  background: #e5f4ff;
  padding: 19px 0px;
  position: relative;

  @media screen and (min-width: $md) {
    margin-bottom: 40px;
  }

  @media screen and (min-width: $lg) {
    margin-bottom: 60px;
  }

  @media screen and (max-width: $xxl) {
    width: 70%;
  }

  @media screen and (max-width: $xl) {
    width: 90%;
  }

  .container {
    padding: 2px 16px;
    display: flex;
    justify-content: center;

    h5 {
      font-weight: 400;
    }
  }
}

.social_wrapper {
  padding: 20px 60px;
  @media screen and (max-width: $md) {
    padding: 20px 30px;
  }
  @media screen and (max-width: $xl) {
    padding: 20px 30px;
  }

  .social_img_title {
    display: flex;
  }

  .social_img {
    width: 10%;
    border-radius: 50%;

    @media screen and (max-width: $sm) {
      width: 20%;
    }
  }

  .social_name {
    display: flex;
    align-items: center;
    margin-left: 30px;
    @media screen and (max-width: $md) {
      flex-direction: column;
    }
    @media screen and (max-width: $sm) {
      margin-left: 16px;
    }

    p {
      margin-left: 32px;
      font-size: 14px;
      color: #818181;
      @media screen and (max-width: $md) {
        margin-left: 0;
      }
    }
  }

  .social_para {
    margin-left: 96px;
    margin-top: -20px;
    @media (min-width: 1399px) and (max-width: 1500px) {
      margin-left: 77px;
      margin-top: 0px;
    }
    @media screen and (max-width: $xl) {
      margin-left: 117px;
      margin-top: -42px;
    }
    @media screen and (max-width: $lg) {
      margin-left: 95px;
      margin-top: -26px;
    }
    @media screen and (max-width: $md) {
      margin-left: 0;
      margin-top: 16px;
    }

    textarea {
      font-size: 14px;
      line-height: 23px;
      background: white;
      padding: 28px 10px;
      border-radius: 7px;
      border: none;
      width: 100%;
      height: 21vh;

      &:focus {
        outline: none;
      }
    }

    .social_comments {
      display: flex;
      justify-content: flex-end;

      h6 {
        font-weight: 100;
      }
    }
  }

  .social_btn {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    button {
      color: #fff;
      background: #1d9bf0;
      border-radius: 30px;
      padding: 7px 24px;
      border: none;
      cursor: pointer;
      text-transform: capitalize;
    }
  }

  .text-red {
    color: $red;
  }
}
</style>