import api from "./api";
import { API_CONFIG } from "/src/config/api.config.js";
import ErrorService from "@/services/error.service"

class ApiCallsService {
	async makeDonation(donation) {
		let apiUrl = API_CONFIG.storeDonation() // IE, `/store/donation`

		return await api
			.post(apiUrl, donation)
			.then(response => {
				if (response.status === 200 && response.data && response.data.billingAuthNo) return response.data
				return {}
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "donation")
			});
	}

	async makePurchase(purchase) {
		const url = API_CONFIG.storePurchase(purchase.RegID)

		return await api
			.post(url, purchase)
			.then(response => {
				if (response.status === 200 && response.data && response.data.billingAuthNo) return response.data
				return {}
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "purchase")
			});
	}

	async getStatesAndCountries(options) {
		const needCountryList = options.needCountryList || true
		const countryName = options.countryName || "United States"
		let apiUrl = API_CONFIG.getStatesAndCountries() // IE, `/action/states/`

		var data = {
			NeedCountryList: needCountryList,
			CountryName: countryName
		}

		return await api
			.post(apiUrl, data)
			.then(response => {
				return response.data;
			}).catch(e => {
				console.error('Error in apiCalls.service, ', e)
			});
	}

	async InviteNewFriend(invitation) {
		let apiUrl = API_CONFIG.inviteNewFriend() // IE, `/action/invite-friend`

		return await api
			.post(apiUrl, invitation)
			.then(response => {
				if (response.status === 200) return true
				return response;
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "invitation")
			});

	}

	async registerExistingFriend(existingFriend) {
		let apiUrl = API_CONFIG.registerExistingFriend() // IE, `/action/register-existing/`

		return await api
			.post(apiUrl, existingFriend)
			.then(response => {
				return response.data;
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "register-existing")
			});
	}

	async registerNewFriend(newFriend) {
		let apiUrl = API_CONFIG.registerNewFriend() // IE, `/action/register/`

		return await api
			.post(apiUrl, newFriend)
			.then(response => {
				return response.data;
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "registration")
			});
	}

	async updateExistingFriend(updateFriend) {
		if (!updateFriend.CstKey) return "Missing CstKey, cannot update Friend"

		const url = API_CONFIG.updateExistingFriend(updateFriend.CstKey)

		return await api
			.post(url, updateFriend)
			.then(response => {
				if (response && response.status === 200) return response.data
				return response
			}).catch(function (error) {
				return ErrorService.handleErrorFromApi(error, "update")
			});
	}

}

export default new ApiCallsService();
